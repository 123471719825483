import { useReducer, useContext, createContext } from "react"; //首先引入需要的hooks
import { getToken } from '@/utils/auth'
import { FormInstance } from 'antd';

export interface AuthState {
  count?: number;
  token?: string;
  formInstance?:FormInstance
}

export interface UserState {
  count: number;
}
 //编写操作需要的TYPES
export const AuthTypes = {
  SET_TOKEN: "SET_TOKEN",
  SET_AUTH: "SET_AUTH",
  FORMINSTANCE: "FORMINSTANCE",
};

// 初始化状态
export const initState: AuthState = {
  token: getToken(),
  count: 0,
  formInstance: undefined
};

//建一个reducer纯函数
export const reducer = (state, action) => {
  switch (action.type) {
    case AuthTypes.SET_TOKEN:
      return { ...state, token: action.token };
    case AuthTypes.SET_AUTH:
      return { ...state, ...action.payload };
    case AuthTypes.FORMINSTANCE:
      return { ...state, formInstance: action.payload };
    default:
      return state;
  }
};

// 使用useReducer生成一个可以创建数据操作读写API的函数
export const useInitAuthStore = (_initState) => {
  return useReducer(reducer, { ...initState, ..._initState });
};

//创建Context,暴露给外层组件用
// export const AuthContext = createContext<[AuthState, React.Dispatch<any>]>([initState, () => {}]);
export const AuthContext = createContext({} as any);

//使用useContext和Context获取读写API,这里封装成一个函数，外层组件可直接通过调用useStore函数获取
export const useAuthStore = () => {
  return useContext(AuthContext) || {};
}; 