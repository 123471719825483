import { useRoutes } from 'react-router-dom';
import routes from './pages/routes';
import AuthProvide from "@/contexts/index"; //父组件内引入useStore文件里提前暴露好的初始化store函数和Context

// 导入store
import store from './store'
// 导入store提供组件Provider
import { Provider } from 'react-redux'

function App() {
  const config = useRoutes(routes);
  return <Provider store={store}>
    <AuthProvide>
      <div className="App">{config}</div>
    </AuthProvide>
  </Provider>
}

export default App;
