import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserLogin, fetchUserInfo } from "@/api/index";
import { FormInstance } from 'antd';

// 创建切片 slices
// 一个切片是一个包含 reducer 函数和 action creator 的对象。它定义了一部分状态和与该状态相关的操作。
// createSlice返回值是一个对象，包含所有的actions;
const formSlice = createSlice({
  name: 'form',
  initialState: {
    formInstance: {} as FormInstance,
    formData: {
      
    },
  },
  reducers: {
    // 参数一: state, 当前的state状态 // 参数二: 传递的actions参数, actions有两个属性, 一个是自动生成的type, 另一个是传递的参数放在payload中;
    setStoreFormInstance(state, { payload }) {
      state.formInstance = payload;
    },
    // setGenerating(state, { payload }) {
    //   state.generating = payload;
    // },
  }
});

// 解构出 actionCreater 函数
// const { setStoreFormInstance, setGenerating } = formSlice.actions;

// export { setStoreFormInstance,setGenerating };
const { setStoreFormInstance  } = formSlice.actions;

export { setStoreFormInstance };
  
// 导出 reducer 函数
export default formSlice.reducer;