import { builtinExtensions } from "@/builtin-extensions";
import { StableDiffusionWebUIConfig } from "@/types/config";


export const DefaultStableDiffusionWebUIConfig: StableDiffusionWebUIConfig = {
  generalConfig: {
    baseAPI: {
      tooltip: 'Stable Diffusion 地址',
      contentType: 'string',
      // value: import.meta.env.VITE_APP_AIGC_API
      // value: 'https://aigc-sd-api.yiihuu.com'
      // value: 'http://test-aigc-sd-api.goyor.com:81'
      // value: 'https://u181628-af01-67af9519.cqa1.seetacloud.com:8443',
    }
  },
  builtinExtensions
};

// 获取配置
export const getOrInitConfig = (): StableDiffusionWebUIConfig => {
  const storedConfig = localStorage.getItem('DefaultStableDiffusionWebUIConfig');
  if (!storedConfig) {
    localStorage.setItem('DefaultStableDiffusionWebUIConfig', JSON.stringify(DefaultStableDiffusionWebUIConfig))
    return DefaultStableDiffusionWebUIConfig;
  }
  try {
    return JSON.parse(storedConfig);
  } catch (error) {
    console.error('[Config-getOrInitConfig] parse stored config error: ', error);
    return DefaultStableDiffusionWebUIConfig;
  }
};

// 设置保存配置
export const saveConfig = (config: StableDiffusionWebUIConfig) => {
  localStorage.setItem('DefaultStableDiffusionWebUIConfig', JSON.stringify(config))
};

// 获取基础SDBaseAPI 配置地址
export const getSDBaseAPI = () => {
  // const config = getOrInitConfig();
  // return config?.generalConfig?.baseAPI?.value;
  return import.meta.env.VITE_APP_AIGC_API
}