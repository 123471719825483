import React, { useReducer, useMemo, createContext } from 'react';
import * as authStore from './modules/auth'

export default ({children}: any) => {
  const [contextState, contextDispatch] = authStore.useInitAuthStore({});
  const contextValue = useMemo(() => ({
    contextState,
    contextDispatch,
  }), [contextState]);
  return (
    <authStore.AuthContext.Provider value={contextValue} >
      {children}
    </authStore.AuthContext.Provider>
  );
}