import { configureStore } from '@reduxjs/toolkit';
import authSlice from './modules/auth';
import formSlice from './modules/form';

export { useDispatch, useSelector } from 'react-redux';
export { setStoreToken, setUserInfo, getUserInfo } from './modules/auth';
export { setStoreFormInstance,  } from './modules/form';

// 创建store仓库
// 我们使用 configureStore 函数来创建 Redux Store，并使用 authSlice中的 reducer 将切片与 Store 关联起来。
const store = configureStore({
  reducer: {
    authSlice,
    formSlice
  },
});

export default store