import { getToken, removeToken, setToken } from "@/utils/auth";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserLogin, fetchUserInfo } from "@/api/index"
// 创建切片 slices
// 一个切片是一个包含 reducer 函数和 action creator 的对象。它定义了一部分状态和与该状态相关的操作。
// createSlice返回值是一个对象，包含所有的actions;
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authToken: getToken(),
    userInfo: null,
  },
  reducers: {
    // 参数一: state, 当前的state状态
    // 参数二: 传递的actions参数, actions有两个属性, 一个是自动生成的type, 另一个是传递的参数放在payload中;
    setStoreToken(state, { payload }) {
      state.authToken = payload;
      setToken(payload);
    },
    removeStoreToken(state) {
      state.authToken = "";
      state.userInfo = null;
      removeToken();
    },
    setUserInfo(state, { payload }) {
      state.userInfo = payload;
    }
  }
})

// 解构出 actionCreater 函数
const { setStoreToken, removeStoreToken, setUserInfo } = authSlice.actions;
const getUserInfo = (): any => {
  return async (dispatch) => {
    const res = await fetchUserInfo();
    if (res?.status) {
      dispatch(setUserInfo(res.data));
    }
  }
}

export { setStoreToken, setUserInfo, removeStoreToken, getUserInfo, }
// 导出 reducer 函数
export default authSlice.reducer;